import {
  GlobalOutlined,
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  CommentOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useRootStore } from "../../context";
import { Dropdown, Layout, MenuProps, Space } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getListOfRegions } from "../../stores/region";
import { Region } from "../../stores/auth";

const items: MenuProps["items"] = [
  {
    label: "Contact support",
    key: "feedback",
    icon: <CommentOutlined />,
  },
  {
    label: "Documentation",
    key: "how-to-use",
    icon: <QuestionCircleOutlined />,
  },
  {
    label: "Profile settings",
    key: "settings",
    icon: <SettingOutlined />,
  },
  { type: "divider" },
  {
    icon: <LogoutOutlined />,
    key: "logout",
    label: "Logout",
  },
];

function Header() {
  const { auth } = useRootStore();
  const navigate = useNavigate();

  const [regionList, setRegionList] = React.useState([]);

  React.useEffect(() => {
    async function init() {
      const rs = await getListOfRegions();
      setRegionList(rs);
    }

    init();
  }, []);

  return (
    <Layout.Header className="top-header">
      {auth.hasUserDetails && (
        <>
          <Dropdown
            menu={{
              onClick: async ({ key }) => {
                await auth.setRegion(key as Region);
                navigate(`/buckets`);
              },
              items: [
                {
                  key: "subtitle",
                  type: "group",
                  label: "Choose region",
                  children: regionList.map((r) => ({
                    key: r.name,
                    label: r.name,
                    disabled: auth.r && r.name === auth.r.name,
                  })),
                },
              ],
            }}
          >
            <Space
              size="small"
              style={{ cursor: `pointer`, marginLeft: `auto` }}
            >
              <GlobalOutlined />
              {auth.r && auth.regionalCookie ? auth.regionalCookie : `...`}
              <DownOutlined />
            </Space>
          </Dropdown>

          <div className="vl" />

          <Dropdown
            menu={{
              onClick: ({ key }) => {
                if (key === `logout`) {
                  auth.logout();
                }

                if (key === `settings`) {
                  navigate(`/settings`);
                }
              },
              items,
            }}
          >
            <Space size="small" style={{ cursor: `pointer` }}>
              <UserOutlined />
              {auth.profile?.email}
              <DownOutlined />
            </Space>
          </Dropdown>
        </>
      )}
    </Layout.Header>
  );
}

export default observer(Header);
