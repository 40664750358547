import { ApiError } from "../types/api";
import { notification } from "antd";
import { ArgsProps } from "antd/es/notification";
import { Options } from "ky";

// Type guard
function hasMessage(o: unknown): o is { message: string } {
  return (o as { message: string }).message !== undefined;
}

const ONE_MINUTE = 60 * 1000;

export function kySettings(): Options {
  return {
    credentials: "include",
    timeout: ONE_MINUTE,
    retry: 2,
    hooks: {
      beforeError: [
        async (error: ApiError) => {
          error.status = error.response.status;
          error.statusText = error.response.statusText;

          const text = await error.response.text();

          try {
            const obj = JSON.parse(text) as unknown as { error?: string };

            if (typeof obj === `object`) {
              if (`error` in obj) {
                error.data = obj.error;
              } else {
                error.data = obj;
              }

              if (hasMessage(error.data)) {
                error.message = error.data.message;
              }
            }
          } catch (_) {
            error.message = text || `Unknown error`;
          }

          error.notify = (params: ArgsProps) => {
            const { type = `error`, ...rest } = params || {};
            const config = {
              message: error.message,
              description: error.name,
              ...rest,
            };
            notification[type](config);
          };

          return error;
        },
      ],
    },
  };
}
