import React from "react";
import root, { RootStore } from "./stores/root";

export type ReactChildren =
  | React.ReactElement
  | React.ReactElement[]
  | React.ReactNode
  | React.ReactNode[];

const RootStoreContext = React.createContext<RootStore>(root);

export const useRootStore = (): RootStore => React.useContext(RootStoreContext);

export function RootStoreProvider({ children }: { children: ReactChildren }) {
  if (process.env.NODE_ENV === "development") {
    window._root = root;
    window.tjs = (v): void => console.log(JSON.parse(JSON.stringify(v)));
  }

  return (
    <RootStoreContext.Provider value={root}>{children}</RootStoreContext.Provider>
  );
}
