import React from "react";
import { observer } from "mobx-react-lite";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

import Header from "./parts/Header";
import SideNav from "./parts/SideNav";
import { useRootStore } from "./context";
// import Buckets from "./pages/Buckets";

import "./styles/index.scss";
import Layout from "./parts/Layout";
import { ConfigProvider } from "antd";
import { antdThemeConfig } from "./antd-theme-config";
// import Keys from "./pages/Keys";

const APP_PAGES: Record<string, string> = {
  "/buckets": "Buckets",
  "/keys": "Keys",
};

function Buckets() {
  return <div>Bucket</div>;
}

function Keys() {
  return <div>Keys</div>;
}

function ProtectedApp() {
  const location = useLocation();

  return (
    <div style={{ display: `flex`, flexDirection: `row` }}>
      <ConfigProvider theme={antdThemeConfig}>
        <aside className="sider">
          <SideNav />
        </aside>
        <Layout title={APP_PAGES[location.pathname]} extra={<Header />}>
          <Routes>
            <Route path="/buckets" element={<Buckets />} />
            <Route path="/keys" element={<Keys />} />
            <Route path="*" element={<Navigate to="/buckets" replace />} />
          </Routes>
        </Layout>
      </ConfigProvider>
    </div>
  );
}

export default observer(ProtectedApp);
