import { makeObservable, action, observable, runInAction } from "mobx";
import type {
  ListAccessKeysCommandOutput,
  AccessKeyMetadata,
  CreateAccessKeyCommandOutput,
  AccessKey,
} from "@aws-sdk/client-iam";
// import axios from 'axios';
import { notification } from "antd";
import type { RootStore } from "./root";

export class KeyStore {
  root: RootStore;

  keys: AccessKeyMetadata[] = [];

  loading = {
    list: false,
    create: false,
  };

  get apiClient() {
    return this.root.auth.r.apiClient;
  }

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      loading: observable,
      setLoading: action,
      keys: observable,
    });
  }

  async list(UserName?: string) {
    if (this.loading.list) return;

    this.setLoading("list", true);

    try {
      const data = await this.apiClient
        .post(`api/v2/iam/keys/list`, {
          json: { UserName },
        })
        .json<ListAccessKeysCommandOutput>();

      if (data && data.AccessKeyMetadata) {
        runInAction(() => {
          this.keys = data.AccessKeyMetadata;
        });
      }
    } catch (e) {
      notification.error({
        message: `Cannot get access keys`,
        description: (e as Error).toString(),
      });
    } finally {
      this.setLoading("list", false);
    }
  }

  // async remove(accessKeyId: string, userName: string) {
  //   try {
  //     await this.apiClient.delete(`/api/iam/keys/delete`, {
  //       params: {
  //         accessKeyId,
  //         userName: this.root.customer.isSubUser ? userName : "",
  //       },
  //     });

  //     runInAction(() => {
  //       this.keys = this.keys.filter((key) => key.AccessKeyId !== accessKeyId);
  //     });
  //   } catch (e) {
  //     notification.error({
  //       message: `Cannot remove access key`,
  //       description: (e as Error).toString(),
  //     });
  //   }
  // }

  // async create(accountDisplayName?: string): Promise<AccessKey | null> {
  //   if (this.loading.create) return null;

  //   try {
  //     this.setLoading("create", true);
  //     const { data } = await this.apiClient.post<CreateAccessKeyCommandOutput>(
  //       `/api/iam/keys/create`,
  //       {
  //         accountDisplayName,
  //       }
  //     );

  //     await this.list(accountDisplayName);

  //     return data.AccessKey;
  //   } catch (e) {
  //     notification.error({
  //       message: `Cannot create access key`,
  //       description: (e as Error).toString(),
  //     });
  //   } finally {
  //     this.setLoading("create", false);
  //   }
  //   return null;
  // }

  setLoading(k: keyof typeof this.loading, v: boolean) {
    this.loading[k] = v;
  }
}
