import { action, makeObservable, observable, runInAction } from "mobx";

export default class SharedStore {
  loading: Record<string, boolean> = {};

  selected: Record<string, string[]> = {};

  search = ``;

  page: Record<string, number> = {};

  constructor() {
    makeObservable(this, {
      page: observable,
      loading: observable,
      selected: observable,
      search: observable,
      setLoading: action,
      setSelected: action,
      toggleSelection: action,
      setPage: action,
      removeFromSelection: action,
      setSearch: action,
    });
  }

  setLoading(k: keyof typeof this.loading, v: boolean) {
    this.loading[k] = v;
  }

  addLoaders(...keys: string[]) {
    keys.forEach((key) => this.setLoading(key, false));
  }

  addSelections(...keys: string[]) {
    keys.forEach((key) => this.setSelected(key, []));
  }

  addSearches(...keys: string[]) {
    keys.forEach((key) => this.setSearch(key, ``));
  }

  setSelected(key: keyof SharedStore["selected"], value: string[]) {
    this.selected[key] = value;
  }

  setSearch(value: string) {
    this.search = value;
  }

  toggleSelection(key: keyof SharedStore["selected"], value: string) {
    if (this.selected[key].includes(value)) {
      this.removeFromSelection(key, value);
    } else {
      this.setSelected(key, [...this.selected[key], value]);
    }
  }

  removeFromSelection(key: keyof SharedStore["selected"], value: string) {
    this.setSelected(
      key,
      this.selected[key].filter((v) => value !== v)
    );
  }

  setPage(key: keyof SharedStore["page"], n = 1) {
    this.page[key] = n;
  }
}
