export const IAM_PROVIDER_SESSION_KEY = `impcld_session`;

export function setStorageWithExpiration(
  value: unknown,
  expires: number,
  key: string = IAM_PROVIDER_SESSION_KEY
) {
  localStorage.setItem(key, JSON.stringify({ value, expires }));
}

export function getStorageWithExpiration<T>(
  key = IAM_PROVIDER_SESSION_KEY
): [T, number] | [] {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return [];
  }

  try {
    const { value, expires } = JSON.parse(itemStr) as {
      value: T;
      expires: number;
    };

    if (expires < Date.now()) {
      localStorage.removeItem(key);
      return [];
    }

    return [value, expires];
  } catch (e) {
    console.error(`Failed to parse JSON from localStorage: `, e);
    return [];
  }
}

export function updateStorageValue(
  value: Record<string, unknown>,
  key = IAM_PROVIDER_SESSION_KEY
): boolean {
  const [current, exp] = getStorageWithExpiration<Record<string, unknown>>(key);

  if (!current || !exp) {
    return false;
  }

  try {
    setStorageWithExpiration({ ...current, ...value }, exp, key);
    return true;
  } catch (e) {
    console.error(`Failed to parse JSON from localStorage: `, e);
    return false;
  }
}
