import { computed, makeObservable, observable, runInAction } from "mobx";
import { RootStore } from "./root";
import SharedStore from "./base/SharedStore";
import { ApiError } from "../types/api";
import { Bucket, ListBucketsCommandOutput } from "@aws-sdk/client-s3";

export class S3Store extends SharedStore {
  root: RootStore;

  buckets: Bucket[] = [];

  get apiClient() {
    return this.root.auth.r.apiClient;
  }

  get data(): Bucket[] {
    return this.buckets.filter((b) => b.Name.includes(this.search));
  }

  constructor(root: RootStore) {
    super();
    this.root = root;

    makeObservable(this, {
      buckets: observable,
      data: computed,
    });

    this.addLoaders(`fetchBuckets`);
    this.addSelections(`buckets`, `objects`);
  }

  async fetchBuckets(force = false) {
    if (this.loading.fetchBuckets && !force) {
      return;
    }

    if (!this.root.auth.regionalCookie) {
      return;
    }

    try {
      this.setLoading(`fetchBuckets`, true);
      const res = await this.apiClient
        .get("api/v2/s3/bucket")
        .json<ListBucketsCommandOutput>();

      runInAction(() => {
        this.buckets = res.Buckets;
      });
    } catch (e) {
      (e as ApiError)?.notify();
    } finally {
      this.setLoading(`fetchBuckets`, false);
    }
  }

  async createBucket() {}
}
