import ky, { KyInstance } from "ky";
import { makeObservable, observable, runInAction } from "mobx";
import { AuthStore, IRegionDetails, Region } from "./auth";
import { kySettings } from "../lib/ky";
import { setStorageWithExpiration } from "../lib/localStorage";

export interface ISessionStorage {
  email: string;
  parentId: null | string;
}

const _regionsAvailable: IRegionDetails[] =
  process.env.NODE_ENV === `development`
    ? [
        {
          name: `us-west-1` as Region,
          s3Endpoint: `https://us-west-1.storage.impossibleapi.net`,
          bff: `http://localhost:3005`,
        },
      ]
    : [
        {
          name: `us-west-1` as Region,
          s3Endpoint: `https://us-west-1.storage.impossibleapi.net`,
          bff: `https://console.impossiblecloud.com`,
        },
        {
          name: `eu-central-1` as Region,
          s3Endpoint: `https://eu-central-1.storage.impossibleapi.net`,
          bff: `https://console.eu.impossiblecloud.com`,
        },
      ];

// Async in case we'll decide to request available regions from the server in future
export async function getListOfRegions(): Promise<IRegionDetails[]> {
  return Promise.resolve(_regionsAvailable);
}

export const REGION_SESSION_KEY = `impcld_regionSession`;

export class RegionStore {
  auth: AuthStore;

  isRequestingSession = false;

  profile: ISessionStorage | null = null;

  apiClient: KyInstance;

  name: Region;

  constructor(auth: AuthStore, details: IRegionDetails) {
    this.auth = auth;

    this.apiClient = ky.create({
      prefixUrl: details.bff,
      ...kySettings(),
    });

    makeObservable(this, {
      name: observable,
    });

    runInAction(() => {
      this.name = details.name;
    });
  }

  async requestSession(token: string, email: string): Promise<ISessionStorage> {

    if (this.isRequestingSession) {
      console.warn(`Session token has been already requested`);
      return;
    }

    try {
      this.isRequestingSession = true;

      const { expires, ...impossibleCloudProfile } = await this.apiClient
        .post(`api/v2/user/login`, { json: { token, email } })
        .json<ISessionStorage & { expires: number }>();

      return impossibleCloudProfile;
    } catch (e) {
      // Shoud be invalid or expired token, or user not found or anything else
      console.error(`Failed to init regional session: `, e);
    } finally {
      this.isRequestingSession = false;
    }
  }
}
