import React from "react";
import { observer } from "mobx-react-lite";

import { RootStoreProvider, useRootStore } from "./context";
import ProtectedApp from "./ProtectedApp";
import { Button, Spin } from "antd";
import { Region } from "./stores/auth";
import { BrowserRouter } from "react-router-dom";


function App() {
  const { auth } = useRootStore();

  // async function initAppSession() {
  //   if (!auth.hasUserDetails) {
  //     if (!auth.isJustRedirected) {
  //       await auth.iamProviderInitClient();
  //       await auth.iamProviderLogin();
  //     } else {
  //       await auth.iamProviderInitSession();
  //     }
  //   } else {
  //     auth.iamProviderInitSession();
  //   }
  //   await auth.setRegion(Region.US_WEST_1);
  // }

  React.useEffect(() => {
    // initAppSession();
  }, []);

  return (
    <RootStoreProvider>
      {/* {auth.hasUserDetails ? ( */}
      <BrowserRouter>
        <ProtectedApp />
      </BrowserRouter>
      {/* ) : (
        <div
          style={{
            height: `100vh`,
            width: `100%`,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
          }}
        >
          <Spin />
        </div>
      )} */}
    </RootStoreProvider>
  );
}

export default observer(App);
