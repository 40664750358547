import {
  AuditOutlined,
  BookOutlined,
  CodeSandboxOutlined,
  KeyOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useRootStore } from "../../context";
import { Button, Menu, Spin } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./styles.scss";

function highlightKeyByPath(path: string) {
  if (path.startsWith(`/users`)) {
    return [`users`];
  }

  if (path.startsWith(`/groups`)) {
    return [`groups`];
  }

  if (path.startsWith(`/policies`)) {
    return [`policies`];
  }

  if (path.startsWith(`/billing`)) {
    return [`billing`];
  }

  if (path.startsWith(`/buckets`)) {
    return [`buckets`];
  }

  if (path.startsWith(`/keys`)) {
    return [`keys`];
  }

  return [];
}

function SideNav() {
  const navigate = useNavigate();
  // const location = useLocation();
  const { auth } = useRootStore();

  let items = [
    {
      label: `Buckets`,
      link: `/buckets`,
      key: `buckets`,
      icon: <CodeSandboxOutlined />,
    },
    {
      label: `Keys`,
      link: `/keys`,
      key: `keys`,
      icon: <KeyOutlined />,
    },
  ];

  // and has IAM (is root user)
  if (auth.isAuthenticated) {
    items = items.concat([
      {
        label: `Users`,
        link: `/users`,
        key: `users`,
        icon: <TeamOutlined />,
      },
      {
        label: `Groups`,
        link: `/groups`,
        key: `groups`,
        icon: <UsergroupAddOutlined />,
      },
      {
        label: `Policies`,
        link: `/policies`,
        key: `policies`,
        icon: <BookOutlined />,
      },
      {
        label: `Billing`,
        link: `/billing`,
        key: `billing`,
        icon: <AuditOutlined />,
      },
    ]);
  }

  async function handleClick({ key }: { key: string }) {
    const page = items.find((i) => i.key === key);
    if (page) {
      await navigate(page.link);
    }
  }

  return (
    <div>
      <img src="http://localhost:5001/logo-black.png" style={{ maxWidth: `70%`, marginTop: `20px` }} />
      <Menu
        onClick={handleClick}
        selectedKeys={highlightKeyByPath(location.pathname)}
        mode="inline"
        items={items}
      />
    </div>
  );
}

export default observer(SideNav);
