import { AuthStore } from "./auth";
import { ClientStore } from "./client";
import { RegionOldStore } from "./region_old";
import { MFAStore } from "./mfa";
import { IAMStore } from "./iam";
import { ACLStore } from "./acl";
import { S3Store } from "./s3";
import { KeyStore } from "./keys";

export class RootStore {
  auth = new AuthStore(this);
  s3 = new S3Store(this);
  keys = new KeyStore(this);
  // acl = new ACLStore(this);
  // client = new ClientStore(this);
  // iam = new IAMStore(this);
  // mfa = new MFAStore(this);
  // region = new RegionStore(this);
}

export default new RootStore();
