import { observer } from "mobx-react-lite";
import React from "react";

import "./styles.scss";
import Header from "../Header";

interface ILayoutProps {
  title: string;
  extra: React.ReactNode;
  children: React.ReactNode;
}

function Layout(props: ILayoutProps) {
  return (
    <div className="ic-layout">
      <div style={{ display: `flex`, alignItems: `center` }}>
        <h1>{props.title}</h1>
        {props.extra}
      </div>
      {props.children}
    </div>
  );
}

export default observer(Layout);
