import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  createAuth0Client,
  Auth0Client,
  User as IAuth0UserInfo,
} from "@auth0/auth0-spa-js";

import { RootStore } from "./root";
import { RegionStore, getListOfRegions } from "./region";
import {
  IAM_PROVIDER_SESSION_KEY,
  getStorageWithExpiration,
  setStorageWithExpiration,
  updateStorageValue,
} from "../lib/localStorage";

export enum Region {
  US_WEST_1 = "us-west-1",
  EU_CENTRAL_1 = "eu-central-1",
}

export interface IRegionDetails {
  name: Region;
  s3Endpoint: string;
  bff: string;
}

export interface ILocalStorageSession {
  email: string;
  region?: {
    name: Region;
    isRoot: boolean;
    // accountID: string;
  };
}

const CFG =
  process.env.NODE_ENV === `development`
    ? {
        domain: `dev-xqo--nup.us.auth0.com`,
        clientId: `Hl95XkfP4Ar1j2Het5NjYxLgEJx7pqtE`,
        authorizationParams: {
          audience: `https://dev-xqo--nup.us/api/`,
          redirect_uri: window.location.origin,
        },
      }
    : {
        domain: `impossiblecloud.eu.auth0.com`,
        clientId: `RLl94le5BaoCdnQ7cOP2fQM7DxFUd6YU`,
        authorizationParams: {
          audience: `https://impossiblecloud.eu.auth0.com/api/v2/`,
          redirect_uri: window.location.origin,
        },
      };

// AuthStore represents user's browser authorization state. It can be in the following states:
// - Not logged in
// - Has User Details in localStorage
// - Logged in with Auth0 cookie
// - Logged in with Regional cookie
export class AuthStore {
  // This data we got from auth0 callback and store on the client side (browser)
  profile: ILocalStorageSession | null = this.getAuth0Session();

  // This is auth0 client for now
  iamProviderClient: Auth0Client | null = null;

  root: RootStore;

  regionalCookie: Region | null = null;

  isAuthenticated = false;

  r: RegionStore | null = null;

  get hasUserDetails() {
    return !!this.profile;
  }

  get isJustRedirected() {
    return (
      window.location.search.includes(`code=`) ||
      window.location.search.includes(`state=`)
    );
  }

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      profile: observable,
      regionalCookie: observable,
      isAuthenticated: observable,
      hasUserDetails: computed,
      iamProviderSetPermanentSession: action,
    });
  }

  setIsAuthenticated(v: boolean) {
    this.isAuthenticated = v;
  }

  getAuth0Session() {
    const [v] = getStorageWithExpiration<ILocalStorageSession>();
    return v || null;
  }

  iamProviderSetPermanentSession(user: IAuth0UserInfo, exp: number) {
    const p: ILocalStorageSession = { email: user.email || `` };

    const [v] = getStorageWithExpiration<ILocalStorageSession>();
    if (v && `region` in v) {
      p.region = v.region;
    }

    this.profile = p;

    const expires = new Date(exp * 1000).getTime();

    if (expires && !Number.isNaN(expires)) {
      setStorageWithExpiration(this.profile, expires);
    } else {
      console.warn(
        `Failed to store in localStorage: missing expiration time for permanent session`
      );
    }
  }

  async iamProviderInitClient() {
    if (!this.iamProviderClient) {
      this.iamProviderClient = await createAuth0Client(CFG);
    }
  }

  async iamProviderInitSession() {
    const IAM_PROVIDER_REQ_TIMEOUT = 10 * 1000; // 10 seconds timeout for DEVELOPMENT ONLY

    const timeoutPromise = new Promise((resolve) =>
      setTimeout(resolve, IAM_PROVIDER_REQ_TIMEOUT, "TIMEOUT")
    );

    const iamProviderResult = await Promise.race([
      this.iamProviderInitClient(),
      timeoutPromise,
    ]);

    if (iamProviderResult === `TIMEOUT`) {
      console.error(`Failed to request auth0 session`);
      // @todo fallback for DEV env session
      return;
    }

    if (this.isJustRedirected) {
      // Process the login state
      await this.iamProviderClient!.handleRedirectCallback();
      // Use replaceState to redirect the user away and remove the querystring parameters
      window.history.replaceState({}, document.title, "/");
    }

    await this.iamProviderRevalidateSession();
  }

  async iamProviderRequestSession() {
    if (!this.iamProviderClient) {
      console.warn(
        `auth.iamProviderRequestSession: iamProviderClient is not initialized`
      );
      return;
    }

    const v = await this.iamProviderClient.isAuthenticated();
    runInAction(() => {
      this.isAuthenticated = v;
    });
  }

  async iamProviderGetAccessToken() {
    if (!this.iamProviderClient) {
      console.warn(
        `auth.iamProviderGetAccessToken: iamProviderClient is not initialized`
      );
      return;
    }

    return this.iamProviderClient.getTokenSilently();
  }

  async iamProviderGetUser() {
    if (!this.iamProviderClient) {
      console.warn(
        `auth.iamProviderGetUser: iamProviderClient is not initialized`
      );
      return;
    }

    const user = await this.iamProviderClient.getUser();
    console.log(`user`, user);
  }

  async iamProviderRevalidateSession() {
    await this.iamProviderRequestSession();

    if (this.isAuthenticated) {
      const user = await this.iamProviderClient!.getUser();

      if (!user) {
        console.warn(`No user`);
        return;
      }

      const claims = await this.iamProviderClient!.getIdTokenClaims();

      if (!claims || !claims.exp) {
        console.warn(`No claims`, claims);
        return;
      }

      this.iamProviderSetPermanentSession(user, claims.exp);
    } else {
      console.info(`Not authenticated`);
    }
  }

  async iamProviderLogin() {
    if (!this.iamProviderClient) {
      console.warn(
        `auth.iamProviderClient: iamProviderClient is not initialized`
      );
      return;
    }

    await this.iamProviderClient.loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    });
  }

  async iamProviderLogout() {
    if (!this.iamProviderClient) {
      console.warn(
        `auth.iamProviderLogout: iamProviderClient is not initialized`
      );
      return;
    }

    await this.iamProviderClient.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  async logout() {
    await this.iamProviderLogout();
    localStorage.removeItem(IAM_PROVIDER_SESSION_KEY);
    // await this.root.region.apiClient(`api/user/logout`);
  }

  async setRegion(region: Region) {
    const regions = await getListOfRegions();
    const auth0token = await this.iamProviderGetAccessToken();

    const r = regions.find((r) => r.name === region);

    // if (!auth0token) {
    //   console.error(`No auth0 token, can't init regional session`);
    //   return;
    // }

    if (!this.profile) {
      console.error(`No profile, can't init regional session`);
      return;
    }

    this.r = new RegionStore(this, r);

    if (this.profile.region && this.profile.region.name === region) {
      this.regionalCookie = region;
      return;
    }

    const impossibleCloudProfile = await this.r.requestSession(
      auth0token,
      this.profile!.email
    );

    runInAction(() => {
      this.regionalCookie = region;

      this.profile!.region = {
        name: region,
        isRoot: !!impossibleCloudProfile.parentId,
      };
    });

    updateStorageValue({ region: this.profile!.region });
  }
}
