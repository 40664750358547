import { ThemeConfig } from "antd";

export const antdThemeConfig: ThemeConfig = {
  token: {
    fontFamily: `Galano, sans-serif`,
    colorPrimary: "#088310",
    // borderRadius: 0,
    colorPrimaryHover: `#066e0d`,
  },
  components: {
    Layout: {
      headerBg: `#f8f8f6`,
      siderBg: `unset`,
    },
    Button: {
      primaryShadow: "none",
    },
    Menu: {
      itemMarginInline: 0,
      fontSize: 16,
    },
    Modal: {
      borderRadiusLG: 10,
    },
    Switch: {
      opacityLoading: 0.4, // disabled switch
    },
    Input: {
      activeShadow: "0 0 0 2px rgb(11 130 17 / 26%)",
      controlOutline: `rgba(24,144,255,.2)`, // box shadow for focused input
    },
    Form: {
      colorTextHeading: `#7a7a7a`, // labels
    },
    Progress: {
      marginXS: 0,
    },
    Pagination: {
      borderRadius: 6, // = var(--border-radius)
      colorPrimaryHover: "var(--green-dark)",
    },
    Tabs: {
      titleFontSize: 18,
      lineHeight: 1.2,
      inkBarColor: "var(--contrast-green)",
      itemSelectedColor: "var(--contrast-green)",
      itemHoverColor: "var(--green)",
    },
    Table: {
      headerBg: "rgb(255, 255, 255)",
      bodySortBg: `#ffffff`,
      headerSortActiveBg: `#ffffff`,
      fontWeightStrong: 300,
      rowSelectedHoverBg: "#def0e0",
      rowSelectedBg: "var(--green-superlight)",
    },
  },
};
